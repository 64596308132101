//
// _dropdown.scss
//

.dropdown-menu {
    box-shadow: $box-shadow;
    animation-name: DropDownSlide;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    margin: 0;
    position: absolute;
    z-index: 1000;
    &.show {
        top: 100% !important;
    }

    // &[style] {
    //   right: auto!important;
    //   left: 0;
    // }
}

.dropdown-menu-end[style] {
    left: auto !important;
    right: 0 !important;
}

.dropdown-menu[data-popper-placement^="right"],
.dropdown-menu[data-popper-placement^="top"],
.dropdown-menu[data-popper-placement^="left"] {
    top: auto !important;
    animation: none !important;
}

@keyframes DropDownSlide {
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    0% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
    }
}

@media (min-width: 600px) {
    .dropdown-menu-lg {
        width: 320px;
    }

    .dropdown-menu-md {
        width: 240px;
    }
}

.dropdown-divider {
    border-top-color: $border-color;
}

// Dropdown size

.dropdown-mega-menu-xl {
    width: 40rem;
}

.dropdown-mega-menu-lg {
    width: 26rem;
}
