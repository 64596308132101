body {
  background-color: white;
}
.request_details {
  margin-top: 80px;
  margin-left: 20px;
  background-color: white;
}
.request_1 {
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.req_right {
  /* margin-top: 50px; */
  margin-right: 50px;
  width: 40%;

  height: 300px;
}
.req_right img {
  width: 100%;
  height: 100%;
}
.request-123 {
  font-size: 17px;
  font-weight: 500;
  /* text-decoration: underline; */
}
.req-11 p {
  font-size: 10px;
  font-weight: 100;
}
.request-123 {
  width: 100%;
  border-bottom: 0.01px solid rgb(197, 190, 190);
  margin-bottom: -2px;
}
.req_content_left {
  margin-top: 30px;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.input_box {
  margin-bottom: 20px;
}
.req-text-cont {
  font-size: 13px;
  font-weight: 100;
  margin-left: 25px;
}
.request_12 {
  margin-bottom: 50px;
}
RatingTooltip .rating_list_1 {
  margin-left: 56%;
  margin-bottom: 18px;
  margin-left: 70%;
}
.req__145 {
  margin-left: 70%;
}
.content_11 {
  display: inline-block;
}
.content__123 {
  margin-left: 70%;
}
@media screen and (max-width: 768px) {
  .request_1 {
    display: flex;
    height: 90vh;
    flex-direction: column;
    justify-content: space-between;
  }
  .request-123 {
    width: 100%;
    border-bottom: 0.01px solid rgb(197, 190, 190);
  }
  .req_content_left {
    width: 100%;
  }
  .request_12 {
    padding-top: 40px;
  }
  .req_right {
    /* margin-top: 50px; */
    /* margin-right: 50px; */
    width: 90%;

    height: 300px;
  }
}
