side-logo-lg-1 {
  padding-top: 30px;
}
.side-bar-1 {
  padding-top: 20px;
}
.logo-side-1 {
  margin-top: -29px;
  margin-left: -25px;
}
